import React from "react";
import axios from "axios";
import { isMobileSafari, isSafari, osName } from "react-device-detect";
import { serverResponse } from "../util/fakeServer";
import { BREAKPOINT } from "./constant";
import { language } from "../languageProvider/language/language";
import { sendEvent } from "../services/analytics.services";
import {
  worldIcon,
  hatChefIcon,
  specsIcon,
  promoIcon,
  // infoIcon,
  carouselIcon,
  telephoneIcon,
  emailIcon,
  internalIcon,
} from "./constant";
import * as Icone from "../assets/images/icone/index.js";
import InfoSvg from "../assets/images/icons/Informazioni.icon";
import { gaEventLabel } from "../settings/gaConfig";
import ManagerFactory from "../managers/ManagerFactory";
import DetailIcon from "../assets/icons/detail.icon.js";
import VideoIcon from "../assets/icons/video.icon.js";
import CuriosityIcon from "../assets/icons/curiosity.icon.js";
import RecipeIcon from "../assets/icons/recipe.icon.js";
import FormIcon from "../assets/icons/form.icon.js";
import LinkIcon from "../assets/icons/link.icon.js";
var isMobile = window.innerWidth < BREAKPOINT.xs;

const showOnePage = serverResponse.config.show_one_page || isMobile;
const hasIndex = serverResponse.leaflet.index !== null;
const nMax = serverResponse.leaflet.pages.length;
const timeout = 420;

export const VOLANTINO_PRIMARY = "#008DDA";
export const defaultLanguage = serverResponse.config.language;
export const defaultSlide = 10;

const manager = ManagerFactory.getManager();

const getInitialShowOnePage = () => {
  let r = false;
  if (window.innerWidth < BREAKPOINT.xs) {
    r = true;
  } else if (serverResponse.config.show_one_page) {
    r = true;
  }
  return r;
};

export const getValue = (input) => {
  let tempShowOnePage = global.showOnePage || getInitialShowOnePage();
  let tempHasIndex = serverResponse.leaflet.index !== null;
  if (Number.isNaN(input) || input <= 0) {
    return 0;
  } else if (input > nMax) {
    return tempShowOnePage ? nMax : Math.floor(nMax / 2);
    // return tempHasIndex ? temp_m : temp_m - 1;
  } else if (tempShowOnePage) {
    return tempHasIndex ? input : input - 1;
  } else {
    return input % 2 === 0 ? input / 2 : (input - 1) / 2;
  }
};

export const allowPath = serverResponse.config.passwordProtected
  ? ["/login"]
  : ["/login", "/register", "/reset-psw", "/set-psw"];

// Funzione per verificare il path dinamico
export const isAllowedPath = (path) => {
  const dynamicPathRegex = /^\/myGroceryList\/[a-zA-Z0-9]+$/; // RegEx per /myGroceryList/ID_LIST
  return (
    allowPath.includes(path) || // Controlla i path statici
    dynamicPathRegex.test(path) // Controlla il path dinamico
  );
};

export const formatterText = (string) => {
  if (!string) {
    return;
  }
  return string
    .replace("&amp;", "&")
    .replace("&gt;", ">")
    .replace("&lt;", "<")
    .replace("&quot;", '"')
    .replace("&euro;", "€")
    .replace("&apos;", "'");
};

export const setLabelSlider = (index) => {
  let temp = index * 2 + 1 > nMax ? "" : " - " + (index * 2 + 1);
  let tempShowOnePage =
    global.showOnePage || serverResponse.config.show_one_page;
  let tempHasIndex = serverResponse.leaflet.index !== null;
  if (isNaN(index) || index <= 0) {
    return tempHasIndex && tempShowOnePage
      ? language[defaultLanguage].indice
      : 1;
  }
  if (tempShowOnePage) {
    if (index === 0) {
      return tempHasIndex ? language[defaultLanguage].indice : 1;
    } else {
      return tempHasIndex ? index : index + 1;
    }
  } else {
    if (index === 0) {
      return 1;
    } else {
      return index * 2 + temp;
    }
  }
};

export const defaultState = () => {
  let status,
    carouselIndex = 0,
    slideMax;

  if (!showOnePage && hasIndex) {
    status = 1;
    if (nMax % 2 !== 0) {
      slideMax = (nMax + 1) / 2;
    } else {
      slideMax = Math.round(nMax / 2) + 1;
    }
  } else if (!showOnePage && !hasIndex) {
    status = 2;
    if (nMax % 2 !== 0) {
      slideMax = Math.round(nMax / 2);
    } else {
      slideMax = nMax / 2;
    }
  } else if (showOnePage && hasIndex) {
    status = 3;
    slideMax = nMax + 1;
  } else {
    status = 4;
    slideMax = nMax;
  }

  if (isMobile && status === 3) {
    carouselIndex = 0;
  }

  if (nMax === 1) {
    slideMax = 1;
  }

  return {
    loading: false,
    slideMax,
    status,
    carouselIndex,
    labelIndex: setLabelSlider(carouselIndex),
    fromInput: false,
    firstLoad: false,
    zoomValue: 1,
    buttonCarouselDisabled: false,
    refZoom: {},
    modalOpen: false,
    sidebarRightOpen: false,
    verticalPages: defaultSlide,
    isMarkerClickable: true,
    searchBarWidth: 0,
  };
};

// export const gAInitializer = () => {
//   if (serverResponse.config.ga_active) {
//     ReactGA.initialize(manager.getAnalyticsId(), {
//       gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" }
//     });
//   }
// };

export const checkMobileOs = () => {
  let listOs = ["android", "ios"];

  return listOs.includes(osName.toLowerCase().trim());
};

const isFacebookApp = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
};

const isInstagramApp = () => {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf("Instagram") > -1;
};

export const lightenDarkenColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const getPager = () => {
  let slides_count = global.showOnePage ? nMax : Math.floor(nMax / 2);
  let pager = null;
  if (nMax > 1) {
    if (serverResponse.config.pager === "index") {
      pager = "index";
    } else if (serverResponse.config.pager === "slider") {
      pager = slides_count < 7 ? "buttons" : "slider";
    } else if (serverResponse.config.pager === "buttons") {
      if (global.showOnePage) {
        pager = slides_count > 8 ? "slider" : "buttons";
      } else {
        pager = slides_count > 15 ? "slider" : "buttons";
      }
    }
  }

  return pager;
};

export const getPagesFromLabel = (str) => str.split(" - ");

export const getCustomActions = () => {
  let obj = {
    component: "Default",
    sendList: (exportObj, idParam) => {
      const url = `${process.env.REACT_APP_ENDPOINT}/grocery-list/write`;

      axios
        .post(url, exportObj)
        .then(function (response) {
          // Assicurati che tutti i dati siano stringhe
          const clientId = String(serverResponse.config.client_id);
          const signboardId = String(serverResponse.config.signboard_id);
          const listId = String(idParam);
          const idPublication = String(serverResponse.leaflet.id);
          const publicationUrl = String(exportObj.flyer_url);
          const linkLogo = String(serverResponse.config.logo_full);
          const pid = String(exportObj.pid);

          // Crea la stringa di query
          const queryString = `client_id=${clientId}&signboard_id=${signboardId}&list_id=${listId}&id_publication=${idPublication}&publication_url=${publicationUrl}&link_logo=${linkLogo}&pid=${pid}`;

          // Codifica in base64 utilizzando btoa
          const encodeString = btoa(queryString);

          var send_grocery_list_url = `${
            serverResponse.config.type === "inpublish"
              ? process.env.REACT_APP_INPUBLISH_URL
              : process.env.REACT_APP_PORTAL_URL
          }/clienti/checkout/index?params=${encodeString}`;

          if (isSafari) {
            setTimeout(() => {
              window.open(send_grocery_list_url, "_blank");
            });
          } else if (isFacebookApp() || isInstagramApp()) {
            window.location.href = send_grocery_list_url;
          } else {
            const openTo = isMobileSafari ? "_self" : "_blank";
            window.open(`${send_grocery_list_url}`, openTo);
          }
        })
        .catch(function (error) {
          // alert(
          //   `Spiacenti, si è verificato un errore ${JSON.stringify(error)}`
          // );
          console.log(JSON.stringify(error));
        });
    },
    bgTotalList: serverResponse.config.primary_color,
    exportList: (exportObj) => {
      axios.post(
        `${process.env.REACT_APP_ENDPOINT}/grocery-list/write`,
        exportObj
      );
    },
    get_read_api_url: (id, id_campaign) =>
      `${process.env.REACT_APP_ENDPOINT}/grocery-list/read?id_param_url=${id}`,
    get_product_api_url: (res) => res.data.data,
    backToLeaflet: (func_write, func_history) => null,
    get_list_container_class: "custom-container",
    getShareListLink: (idParam) =>
      serverResponse.config.serverPath + "myGroceryList/" + idParam,
    classColumn: true,
    listData: (el) => el.data,
    isIframe: () => {
      try {
        return window.self !== window.top;
      } catch (error) {
        return;
      }
    },
    cookieConsent: false,
    getInteractivity: (item_id) => {
      return serverResponse.interactivities[item_id];
    },
  };

  return obj;
};

export const encodeN = (n) => {
  const key = [4, 5, 6, 7, 8, 9, 0, 1, 2, 3];
  return key[n];
};

const getDate = () => {
  let d = new Date();
  return d.toISOString().split("T")[0];
};

export const setCookiesBrowser = (cookiesArray) => {
  cookiesArray.forEach((cookie) => {
    const { name, value } = cookie;
    localStorage.setItem(name, value);
  });
};

export const getCookieBrowser = (nomeCookie) => {
  const value = localStorage.getItem(nomeCookie);
  return value;
};

export const sendVisit = () => {
  const isForced = manager.forceVisit();
  const statisticheCookieValue = getCookieBrowser("statistiche");
  if (isForced || statisticheCookieValue === "true") {
    let isSended = sessionStorage.getItem(
      `isSended_${serverResponse.leaflet.id}`
    );

    if (!isSended) {
      sessionStorage.setItem(`isSended_${serverResponse.leaflet.id}`, "true");
      return true;
    }
  }
  return false;
};

export const sendAnalyticsEvent = (event, item = null) => {
  const isForced = manager.forceVisit();
  const statisticheCookieValue = getCookieBrowser("statistiche");
  let data = null;
  if (isForced ||
    (statisticheCookieValue === "true" ||
    serverResponse.config.hide_cookie_banner)
  ) {
    data = {
      id_cliente: serverResponse.config.client_id,
      id_insegna: serverResponse.config.signboard_id,
      id_utente: serverResponse.config.release_id,
      id_volantino: serverResponse.leaflet.id,
    };
    if (serverResponse.config.ga_active && manager.sendAnalitics() && data) {
      manager.gaAnalyticsEvent(event, item);
      sendEvent({
        ...data,
        evento: event,
        id_item: item,
      }).catch((err) => console.log(err));
    } else return;
  }
};

export const getAnalyticsDevice = (isMobile, isTablet, isDesktop) => {
  if (isMobile) return 1;
  else if (isDesktop) return 2;
  else if (isTablet) return 3;
  else return -1;
};

export const getAnalyticsUniqueId = () => {
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  return uid + "_" + getDate();
};

export const iconsObj = {
  plus: {
    icon: manager.getPlusIcon(),
    gaLabel: gaEventLabel.open_product,
    component: manager.getPlusIcon() || <DetailIcon />,
  },
  carousel: {
    icon: carouselIcon,
    gaLabel: gaEventLabel.open_carousel,
    component: <Icone.Carosello />,
  },
  world: {
    icon: worldIcon,
    gaLabel: gaEventLabel.open_product_link,
    component: <LinkIcon />,
  },
  play: {
    icon: manager.getPlayIcon(),
    gaLabel: gaEventLabel.open_product_video,
    component: manager.getPlayIcon() || <VideoIcon />,
  },
  info: {
    icon: manager.getInfoIcon(),
    gaLabel: gaEventLabel.open_product_curiosity,
    component: <CuriosityIcon />,
  },
  "hat-chef": {
    icon: hatChefIcon,
    gaLabel: gaEventLabel.open_product_recipe,
    component: <RecipeIcon />,
  },
  specs: {
    icon: specsIcon,
    gaLabel: gaEventLabel.open_product_specifications,
    component: <FormIcon />,
  },
  promo: {
    icon: promoIcon,
    gaLabel: gaEventLabel.open_promo,
  },
  external_link: {
    icon: (type_external) => {
      switch (type_external) {
        case "telephone":
          return telephoneIcon;
        case "email":
          return emailIcon;
        default:
          return worldIcon;
      }
    },
    action: (payload) => console.log(""),
    gaLabel: gaEventLabel.open_product_link,
    component: <Icone.LinkEsterno />,
  },
  url: {
    icon: (type_external) => {
      switch (type_external) {
        case "telephone":
          return telephoneIcon;
        case "email":
          return emailIcon;
        default:
          return worldIcon;
      }
    },
    action: (payload) => console.log(""),
    gaLabel: gaEventLabel.open_product_link,
    component: <Icone.Web />,
  },
  email: {
    icon: (type_external) => {
      switch (type_external) {
        case "telephone":
          return telephoneIcon;
        case "email":
          return emailIcon;
        default:
          return worldIcon;
      }
    },
    action: (payload) => console.log(""),
    gaLabel: gaEventLabel.open_product_link,
    component: <Icone.Email />,
  },
  telephone: {
    icon: (type_external) => {
      switch (type_external) {
        case "telephone":
          return telephoneIcon;
        case "email":
          return emailIcon;
        default:
          return worldIcon;
      }
    },
    action: (payload) => console.log(""),
    gaLabel: gaEventLabel.open_product_link,
    component: <Icone.Telefono />,
  },
  internal_link: {
    icon: internalIcon,
    action: (payload) => console.log(""),
    gaLabel: gaEventLabel.open_product_link,
    component: <Icone.LinkInterno />,
  },
  informazioni: {
    icon: InfoSvg,
    gaLabel: gaEventLabel.open_product,
    component: <Icone.Informazioni />,
  },
  attachment: {
    icon: <Icone.Allegato />,
    gaLabel: gaEventLabel.open_product,
    component: <Icone.Allegato />,
  },
};

export const scrollToSlide = (id, fc, verticalPages) => {
  if (!isMobile) return;
  const scrollable = document.querySelector(".Carousel");
  const pagesL = serverResponse.leaflet.pages.length;
  const slide = document.getElementById(`slide_${id}`);

  if (isNaN(id) || id <= 0) {
    scrollable?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return;
  }
  // if(typeof id !)
  if (id > pagesL) {
    while (verticalPages <= pagesL) {
      fc();
      verticalPages += defaultSlide;
    }
    setTimeout(() => {
      document.getElementById(`slide_${pagesL}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, timeout);
  } else {
    if (id <= verticalPages) {
      setTimeout(() => {
        slide.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, timeout);
    } else {
      while (verticalPages <= id) {
        fc();
        verticalPages += defaultSlide;
      }
      setTimeout(() => {
        document.getElementById(`slide_${id}`).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, timeout);
    }
  }
};

const support_format_webp = () => {
  var elem = document.createElement("canvas");
  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};

export const isSupported =
  serverResponse.config.hasWebp && support_format_webp();

export const animationType = (type) => {
  switch (type) {
    case 1:
      return "zoomInAnimation";
    case 2:
      return "zoomOutAnimation ";
    case 3:
      return "slideOutAnimation";
    case 4:
      return "slideInAnimation";

    default:
      return "noAnimation";
  }
};

export const messageError = (status) => {
  switch (status) {
    case 403:
    case 404:
      return language[defaultLanguage].email_pass_non_trovati;
    case 409:
      return language[defaultLanguage].email_presente;
    default:
      return language[defaultLanguage].parametri_mancanti;
  }
};
