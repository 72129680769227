import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { serverResponse } from "../util/fakeServer";
import { allowPath, isAllowedPath } from "../helper/utility";

const PrivateRoute = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    try {
      setLoading(true);
      if (serverResponse.config.only_logged && !props.authJWT.isAuthenticated && props.authJWT.loadingProfile && !isAllowedPath(location.pathname)) {
        navigate("/login");
      } else if (!serverResponse.config.allow_login && location.pathname === "/login") {
        navigate("/leaflet");
        setLoading(false);
      }else if (serverResponse.config.allow_login && location.pathname === "/") {
        navigate("/leaflet");
        setLoading(false);
      }else if (serverResponse.config.allow_login && location.pathname !== "/") {
        navigate(location.pathname);
        setLoading(false);
      }else if (!serverResponse.config.allow_login && location.pathname === "/") {
        navigate("/leaflet");
        setLoading(false);
      }else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      navigate("/login");
    }
    
    return () => setLoading(false);
  }, [navigate, location.pathname, props.authJWT.isAuthenticated, props.authJWT.loadingProfile]);

  if (loading) {
    return
  }

  return <Outlet/>;
};

const mapStateToProps = (state) => ({
  authJWT: state.authJWT,
});

export default connect(mapStateToProps)(PrivateRoute);
