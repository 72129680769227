import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { setSearchbarWidth } from "../../redux/carousel/action";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import ManagerFactory from "../../managers/ManagerFactory";
// import "../../assets/css/sidebar.css";
import "./sidebar.css";
import withLocation from "../common/WithLocation";
import { isSidebarRightOpen } from "../../redux/carousel/action";
import { BREAKPOINT } from "../../helper/constant";
const manager = ManagerFactory.getManager();
class NavList extends Component {
  state = {
    collapse: false,
    tooltip: false,
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount = () => {
    // this is for opening collapse for active link
    if (this.props.list.child) {
      const finalList = this.props.list.child.filter(
        (a) => !a.hasOwnProperty("child")
      );
      const threeList = this.props.list.child.filter((a) =>
        a.hasOwnProperty("child")
      );
      const finalThreelevel = [];
      if (threeList.length) {
        threeList.forEach((element) => {
          element.child.forEach((ele) => {
            finalThreelevel.push(ele.routepath);
          });
        });
      }
      if (
        (finalList.length &&
          finalList
            .map((a) => a.routepath)
            .includes(this.props.location.pathname)) ||
        (finalThreelevel &&
          finalThreelevel.includes(this.props.location.pathname))
      ) {
        this.setState({
          collapse: true,
        });
      }
    }
  };

  render() {
    let dynamicList;
    
    const { list, setSearchbarW } = this.props;
    const isMobileOrTablet = window.innerWidth <= BREAKPOINT.xl;

    dynamicList = (
      <li
        className={this.props.mini ? "sidebar-nav-item" : ""}
        id={list.name}
        style={{ position: "relative" }}
        onMouseEnter={() => {
          // if (this.props.themeSetting === "on")
          this.setState({ tooltip: true });
        }}
        onMouseLeave={() => this.setState({ tooltip: false })}
      >
        {list.routepath ? (
          <NavLink
            to={list.routepath}
            className="nav-link main-list"
            onClick={(e) => {
              // forceDrawerMini();
              if (this.props.searchBarOpen) setSearchbarW();
              list.routepath && list.action(e);
            }}
          >
            {/* <i className={list.iconClass} /> */}
            <list.component />
            <span className="nav-item-label">{list.name}</span>
            {/* <p
              className={this.props.mini ? "fade_p" : ""}
              style={{ opacity: this.props.mini ? null : "1" }}
            >
              {list.name}
            </p> */}
          </NavLink>
        ) : (
          // style={{
          //   cursor: "pointer",
          //   opacity: this.props.location.pathname !== "/leaflet" ? 0.5 : 1,
          //   filter:
          //     Object.keys(serverResponse.interactivities).filter(
          //       (el) => serverResponse.interactivities[el].type === "product"
          //     ).length > 0
          //       ? "blur(0)"
          //       : "blur(1px)",
          // }}
          <a
            className="nav-link main-list"
            href="#!"
            style = {{
              pointerEvents : this.props.location.pathname !== "/leaflet" ? "none" : "auto",
              opacity: this.props.location.pathname !== "/leaflet" ? 0.5 : 1,
            }}
            onClick={() => {
              if (
                this.props.location.pathname !== "/categories" &&
                this.props.location.pathname !== "/pages" &&
                Object.keys(serverResponse.interactivities).filter(
                  (el) => serverResponse.interactivities[el].type === "product"
                ).length > 0
              )
                setSearchbarW(isMobileOrTablet);
            }}
          >
            <list.component />
            <span className="nav-item-label">{list.name}</span>
            {/* <p
              className={this.props.mini ? "fade_p" : ""}
              style={{ opacity: this.props.mini ? null : "1" }}
            >
              {list.name}
            </p> */}
          </a>
        )}
      </li>
    );

    return <Fragment>{manager.showList() && dynamicList} </Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    themeSetting: "on",
    searchBarOpen: state.carousel.searchBarOpen,
  };
};
function mapDispatchToProps(dispatch, state) {
  return {
    setSearchbarW: (isMobileTemplate) => {
      dispatch(setSearchbarWidth());
      if (isMobileTemplate) {
        if (state.sidebarRightOpen) {
          dispatch(isSidebarRightOpen(false));
        }
      }
    },
  };
}
export default withLocation(
  connect(mapStateToProps, mapDispatchToProps)(NavList)
);
