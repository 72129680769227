import React from "react";
import { connect } from "react-redux";
import ReadMore from "../../common/ReadMore";
import GalleryModal from "./components/Gallery.modal";
import TitleModal from "./components/Title.modal";
// import { isMobile } from "react-device-detect";

const InfoBody = ({ modalRedux }) => {
  const tmpTitle = modalRedux.otherProps.markerInfo.title;

  const {
    otherProps: {
      markerInfo: { data },
    },
  } = modalRedux;

  return (
    <div className="row">
      <div
        className="col-12 h-lg-100 col-sm-6 m-auto mt-0"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-sm-6 pt-3">
        <div
          className="mobilelandscape bodyContentSection"
          style={{ height: "calc(80vh - 210px)", overflow: "auto" }}
        >
          <TitleModal title={data.titolo !== 'undefined' ? data.titolo : tmpTitle} />
          <div className="wrapText mt-3">
            <ReadMore>{data.testo}</ReadMore>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};
export default connect(mapStateToProps)(InfoBody);
